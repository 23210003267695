import dynamic from "next/dynamic";

export const DynamicBrandLogo = dynamic(
  () =>
    import(
      "@components/molecules/DynamicBrandLogo/DynamicBrandLogoComponent"
    ).then((mod) => mod.DynamicBrandLogo),
  {
    ssr: false,
  },
);

import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material";
import type { StyledButtonProps } from "./Button.types";

export const ButtonStyled = styled(
  ({
    color = "primary",
    innerRef,
    dataTestId,
    ...props
  }: StyledButtonProps) => (
    <MuiButton
      color={color}
      disableRipple
      data-testid={dataTestId}
      {...props}
      ref={innerRef}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== "noBorders",
  },
)<StyledButtonProps>(({ theme, color = "primary", noBorders }) => {
  return {
    boxSizing: "border-box",
    borderRadius: theme.styles.borderRadius.small,
    border: noBorders
      ? undefined
      : color === "secondary"
        ? `1px solid ${theme.palette.primary[900]}`
        : undefined,
    transition: "all 400ms ease-in-out",

    backgroundColor:
      color === "primary"
        ? theme.palette.primary.main
        : color === "secondary"
          ? theme.palette.common.white
          : theme.palette.error.main,

    ":hover": {
      backgroundColor:
        color === "primary"
          ? theme.palette.primary[700]
          : color === "secondary"
            ? theme.palette.primary[200]
            : theme.palette.error.dark,
    },

    ":active": {
      backgroundColor:
        color === "primary"
          ? theme.palette.primary[800]
          : color === "secondary"
            ? theme.palette.primary[600]
            : theme.palette.error.dark,
    },

    "&.Mui-focusVisible": {
      backgroundColor:
        color === "primary"
          ? theme.palette.primary[700]
          : color === "secondary"
            ? theme.palette.primary[300]
            : theme.palette.error.dark,
    },

    "&.Mui-disabled": {
      border:
        color === "secondary" && `1px solid ${theme.palette.neutral[600]}`,
      backgroundColor:
        color === "primary"
          ? theme.palette.primary[500]
          : color === "secondary"
            ? theme.palette.common.white
            : theme.palette.error.light,
      opacity: theme.palette.opacity[500],
      "& .MuiTypography-root": {
        color: color === "secondary" && theme.palette.neutral[600],
      },
    },

    "&.MuiButton-textSizeLarge": {
      padding: "12px 24px",
      height: 48,
    },

    "&.MuiButton-textSizeMedium": {
      padding: "8px 20px",
      height: 40,
      textTransform: "initial",
    },

    "&.MuiButton-textSizeSmall": {
      padding: "6px 12px",
      height: 36,
      textTransform: "initial",
    },

    "& .MuiTypography-root": {
      color:
        color === "primary"
          ? theme.palette.common.white
          : color === "secondary"
            ? theme.palette.primary[900]
            : theme.palette.common.white,
    },
  };
});

import {
  initSnowplow as _initSnowplow,
  trackPageView,
  identify,
} from "@jeff/web-analytics/src/snowplow";
import { getCookie } from "@integration/cookies";
import type { ExperimentProps } from "@integration/experiments/types";
import type { ExperimentId } from "@integration/experiments/constants";

export const initSnowplow = ({
  appId,
  leadId,
  endpoint,
  experiments,
}: {
  appId: string;
  leadId?: string;
  endpoint?: string;
  experiments?: ExperimentProps;
}) => {
  _initSnowplow({
    endpoint,
    appId,
    leadId,
    getExperiments: () =>
      Object.keys(experiments?.enabled ?? {})
        .filter((name) => experiments?.enabled?.[name as ExperimentId])
        .map((name) => {
          const variant = experiments?.variants?.[name];
          const value =
            variant?.enabled && !!variant?.payload?.value
              ? variant?.payload?.value
              : undefined;
          return {
            experimentId: name,
            variant: value,
          };
        }) ?? [],
    getCookieByName: (name) => {
      const value = getCookie(name);
      if (value === null || value === undefined) {
        return null;
      }
      return value.toString();
    },
  });
};

export { identify, trackPageView };

import { useCallback, useEffect, useState } from "react";
import { useRouter } from "@integration/next-router";
import { getCookie } from "@integration/cookies";
import { PARAM_NAME_PHONE_UNSUBSCRIBE_TOKEN } from "@utils/constants/urlParamNames";
import { useLeadIdNext } from "@domain/leadId/csr/useLeadIdNext";
import { unsubscribePhone, resubscribePhone } from "../utils";
import type { UseIsPhoneUnsubscribeFooterEnabled } from "./types";

export const useIsPhoneUnsubscribeFooterEnabled =
  (): UseIsPhoneUnsubscribeFooterEnabled => {
    const router = useRouter();

    const pathname = router.pathname;

    const [isUnsubFooterEnabled, setIsUnsubFooterEnabled] = useState(false);
    const [isUnsubscribed, setIsUnsubscribed] = useState(false);
    const [token, setToken] = useState("");
    const leadId = useLeadIdNext();

    const params = router.query;

    const phoneUnsubToken =
      params[PARAM_NAME_PHONE_UNSUBSCRIBE_TOKEN] ||
      getCookie(PARAM_NAME_PHONE_UNSUBSCRIBE_TOKEN);

    const handleUnsubClick = useCallback(async () => {
      if (token && leadId) {
        const { status } = await unsubscribePhone(leadId, token);
        if (status === 200) {
          setIsUnsubscribed(true);
        }
      }
    }, [token, leadId]);

    const handleResubClick = useCallback(async () => {
      if (leadId) {
        const { status } = await resubscribePhone(leadId);
        if (status === 200) {
          setIsUnsubscribed(false);
        }
      }
    }, [leadId]);

    useEffect(() => {
      if (phoneUnsubToken && leadId) {
        setIsUnsubFooterEnabled(true);
        setToken(phoneUnsubToken as string);
      }
    }, [params, pathname, leadId, phoneUnsubToken]);

    return {
      isUnsubFooterEnabled,
      isUnsubscribed,
      handleUnsubClick,
      handleResubClick,
    };
  };

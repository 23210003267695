import { useContext, useEffect } from "react";
import { RouteLoadingContext } from "./RouteLoadingContextProvider";
import type { RouteLoadingContextOptions } from "./types";

export const useRouteLoading = (options?: RouteLoadingContextOptions) => {
  const { isPageTransitionLoading, setTransitionState } =
    useContext(RouteLoadingContext);

  useEffect(() => {
    if (options) {
      setTransitionState(options);
    }
  }, [options, setTransitionState]);

  return { isPageTransitionLoading };
};

import { useContext } from "react";
import { LocalisationContext } from "../context";
import type { Context } from "react";
import type { LocalisationContextProps } from "../context";

export const useLocalisationContext = () => {
  return useContext<LocalisationContextProps>(
    LocalisationContext as Context<LocalisationContextProps>,
  );
};

/* eslint-disable no-console */
import { getCookie } from "@integration/cookies/getCookie";
import { COOKIE_NAME_ENABLED_FEATURES } from "@integration/cookies/cookie-names";
import { type ErrorFunc } from "@domain/branding/common";
import { type ExperimentId } from "@integration/experiments/constants";
import type { IncomingMessage, ServerResponse } from "http";
import type { Feature } from "../types";

type Args = {
  name: Feature | ExperimentId;
  ctx?: { req?: IncomingMessage; res?: ServerResponse };
  onError?: ErrorFunc;
};

export const isFeatureEnabled = ({
  name,
  onError = console.error,
  ctx,
}: Args) => {
  try {
    const valueInCookie = getCookie(COOKIE_NAME_ENABLED_FEATURES + name, ctx);
    if (valueInCookie === "true" || valueInCookie === true) {
      return true;
    }
  } catch (err) {
    void onError(err as string | Error);
  }
  return false;
};

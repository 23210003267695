import { onError } from "@integration/bugsnag/csr";
import { getDomainFromOrigin } from "@utils/getDomainFromOrigin";

/**
 * API endpoints were accessed via http proxy (here in the project), due to the pricing per request on Vercel this approach was changed.
 * Now all the API requests are made directly to the backend using api.* and api-dev.* subdomains.
 */
export const apiPrefix = () => {
  try {
    if (typeof window === "undefined") {
      return "/api";
    }

    const { host } = window.location;

    const domain = getDomainFromOrigin({ origin: host });

    if (domain === undefined) {
      return `/api`;
    }
    if (host.indexOf("-dev.") > -1) {
      return `https://api-dev.${domain}`;
    }
    return `https://api.${domain}`;
  } catch (e: unknown) {
    onError(e as Error);
    return "/api";
  }
};

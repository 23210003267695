import { useIsPhoneUnsubscribeFooterEnabled } from "./hooks";
import { BaseLayoutComponent } from "./BaseLayout.component";
import type { BaseLayoutProps } from "./BaseLayout.types";

export const BaseLayout = ({
  content,
  filterBar,
  footer,
  navBar,
  contentSx,
  filterBarSx,
  footerSx,
  navBarSx,
}: BaseLayoutProps) => {
  const {
    isUnsubFooterEnabled,
    isUnsubscribed,
    handleUnsubClick,
    handleResubClick,
  } = useIsPhoneUnsubscribeFooterEnabled();

  return (
    <BaseLayoutComponent
      handleUnsubClick={handleUnsubClick}
      handleResubClick={handleResubClick}
      isUnsubFooterEnabled={isUnsubFooterEnabled}
      isUnsubscribed={isUnsubscribed}
      content={content}
      contentSx={contentSx}
      filterBar={filterBar}
      filterBarSx={filterBarSx}
      footer={footer}
      footerSx={footerSx}
      navBar={navBar}
      navBarSx={navBarSx}
    />
  );
};

import { Stack } from "@mui/material";
import type { ResponsiveSlotProps } from "./component.types";

export const ResponsiveSlot = ({
  children,
  sx = [],
  className,
}: ResponsiveSlotProps) => {
  return (
    <Stack
      className={className}
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={[
        (theme) => ({
          minWidth: "0px",
          boxSizing: "border-box",
          width: "100%",

          [theme.breakpoints.down("md")]:
            theme.styles.responsive.sidePaddings.md_down,
          [theme.breakpoints.down("sm")]:
            theme.styles.responsive.sidePaddings.sm_down,
          [theme.breakpoints.up("md")]:
            theme.styles.responsive.sidePaddings.md_up,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Stack
        direction="row"
        justifyContent="center"
        sx={(theme) => ({
          minWidth: "0px",
          maxWidth: theme.styles.fixedSizes.maxContentWidth,
          width: "100%",
          height: "100%",
          flex: 1,
        })}
      >
        {children}
      </Stack>
    </Stack>
  );
};

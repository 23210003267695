import { withErrorBoundary } from "react-error-boundary";
import { onError } from "@integration/bugsnag/csr";
import { PhoneUnsubFooterFallback } from "./PhoneUnsubFooter.fallback";
import { PhoneUnsubFooterComponent } from "./PhoneUnsubFooter.component";

export const PhoneUnsubFooter = withErrorBoundary(PhoneUnsubFooterComponent, {
  fallback: <PhoneUnsubFooterFallback />,
  onError(error) {
    onError(error);
  },
});

import { Stack, Box, Skeleton } from "@mui/material";
import dynamic from "next/dynamic";
import { branding } from "@domain/branding/common";
import { NavBar } from "@components/organisms/NavBar";
import { BaseLayout } from "@components/templates/BaseLayout";
import { Text } from "@components/atoms/Text";
import { useLocalisationContext } from "@providers/LocalisationProvider/hooks/useLocalisationContext";
import { NavLink } from "@components/atoms/NavLink";
import { DynamicBrandLogo } from "@components/molecules/DynamicBrandLogo";
import type { EmailRegistrationComponentProps } from "./components/component.types";
const SocialSignUpButtons = dynamic(
  () =>
    import("@components/organisms/SocialSignUpButtons").then(
      (mod) => mod.SocialSignUpButtons,
    ),
  {
    ssr: false,
    loading: () => (
      <Skeleton
        sx={{ bgcolor: (theme) => theme.palette.primary?.[200], mb: "10px" }}
        variant="rectangular"
        width="100%"
        height="136px"
      />
    ),
  },
);
const LanguageSwitch = dynamic(
  () =>
    import("@components/molecules/LanguageSwitch").then(
      (mod) => mod.LanguageSwitch,
    ),
  {
    loading: () => (
      <Skeleton
        sx={{ bgcolor: (theme) => theme.palette.primary?.[200], mb: "10px" }}
        variant="rectangular"
        width="107px"
        height="40px"
      />
    ),
  },
);
const EmailForm = dynamic(
  () => import("./components").then((mod) => mod.EmailForm),
  {
    loading: () => (
      <Skeleton
        sx={{ bgcolor: (theme) => theme.palette.primary?.[200], mb: "10px" }}
        variant="rectangular"
        width="100%"
        height={420}
      />
    ),
  },
);

const AdSlot = dynamic(
  () => import("@components/atoms/AdSlot/AdSlot").then((mod) => mod.AdSlot),
  { ssr: false },
);

export const EmailRegistrationComponent = ({
  defaultEmail,
  defaultPhone,
  formErrorMsg,
  formErrorField,
  onSubmit,
  zalo,
  socialSignUpButtonProps,
  leadPageId,
  hasLangSwitch,
  isPageTransitionLoading,
  shouldUseCaptcha,
  shouldDisableFormSubmit,
  shouldDisableFields,
  adsenseProps,
}: EmailRegistrationComponentProps) => {
  const isLeadPage = !!leadPageId;

  const { t, allowedLanguages } = useLocalisationContext();

  return (
    <BaseLayout
      navBar={
        <NavBar
          variant="primary"
          showInPartnershipWith={isLeadPage}
          hasLangSwitch={hasLangSwitch}
        />
      }
      content={
        <Stack
          sx={[
            {
              maxWidth: (theme) => theme.styles.fixedSizes.contentWidth,
              width: "100%",
            },
          ]}
        >
          {adsenseProps && (
            <AdSlot
              slotId={adsenseProps.slotId}
              adsProduct="adsense"
              isAdFrameEnabled={true}
            />
          )}

          <Text
            variant="h3"
            sx={{
              color: (theme) => theme.palette.text?.secondary,
              mb: "14px",
              mt: "18px",
            }}
          >
            {isLeadPage
              ? `${t("registration.leadPageLanding.title")} ${
                  branding.brandName
                }`
              : t("registration.title")}
          </Text>

          <Text variant="contentDefault" mb="34px">
            {t("registration.description")}
          </Text>

          <EmailForm
            onSubmit={onSubmit}
            defaultEmail={defaultEmail}
            defaultPhone={defaultPhone}
            formErrorMsg={formErrorMsg}
            formErrorField={formErrorField}
            leadPageId={leadPageId}
            sx={{ mb: "22px" }}
            isPageTransitionLoading={isPageTransitionLoading}
            shouldUseCaptcha={shouldUseCaptcha}
            shouldDisableFormSubmit={shouldDisableFormSubmit}
            shouldDisableFields={shouldDisableFields}
          />

          <Text variant="contentSM">
            {`${t(
              "registration.tnc.by_clicking_register_now_user_agrees_to",
            )} `}
            <NavLink
              onClick={() => {
                window.open(branding.tncLink);
              }}
              text={t("registration.tnc.terms_and_conditions")}
              sx={{ display: "inline-block" }}
            />
          </Text>

          {isLeadPage && (
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              mt="20px"
            >
              <Box sx={{ fontSize: "10px", maxWidth: "210px" }}>
                {`${t("registration.leadPageLanding.redirect_to_jeff")} ${
                  branding.brandName
                }`}
              </Box>
              <Stack flexDirection="column" alignItems="flex-end">
                <DynamicBrandLogo brandId={branding.brandId} />
                <Box
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "10px",
                    fontWeight: 600,
                    mt: "5px",
                  }}
                >
                  {t("leadPageLanding.in_partnership_with")}
                </Box>
              </Stack>
            </Stack>
          )}

          {socialSignUpButtonProps && (
            <>
              <Text
                mt={"36px"}
                variant="h5"
                sx={{
                  color: (theme) => theme.palette.text?.primary,
                  textAlign: "center",
                }}
              >
                {t("inputs.sign_in.or").toUpperCase()}
              </Text>

              <SocialSignUpButtons
                onSocialSignInReject={
                  socialSignUpButtonProps.onSocialSignInReject
                }
                onSocialSignInSuccess={
                  socialSignUpButtonProps.onSocialSignInSuccess
                }
                sx={{ mb: "21px" }}
                enabledProviders={socialSignUpButtonProps.enabledProviders}
                onSignUpWithZalo={
                  zalo
                    ? () => zalo.onClick({ flow: "registration" })
                    : undefined
                }
              />
            </>
          )}

          {hasLangSwitch && isLeadPage && (
            <Box display="flex" justifyContent="center" mt="24px" mb="84px">
              <LanguageSwitch includedLanguages={allowedLanguages} />
            </Box>
          )}
        </Stack>
      }
    />
  );
};

import { type LoanApplicationInput, ProductType } from "@jeff/lead-api";
import dynamic from "next/dynamic";
import { useRouter } from "@integration/next-router";
import {
  INITIATE_FLOW_PAGE_URI,
  PAGE_URI_BUY_INSURANCE_MOTORBIKE,
  PAGE_URI_BUY_INSURANCE_AUTO_REDIRECT,
  PARAM_NAME_FLOW_ID,
  PARAM_NAME_PARTNER_URL_KEY,
  OFFERS_PAGE_URI,
  LEAD_PAGE_LANDING_URI,
  PARAM_NAME_REDIRECT_AFTER_REG,
  PARAM_NAME_PLATFORM,
  PARAM_NAME_LOAN_APPLICATION_INPUT,
} from "@utils/constants";
import { wrap } from "@integration/error-handling";
import { fetchLeadId } from "@domain/leadId/fetchLeadId";
import { filterQueryParams } from "@integration/query-params/common/filterQueryParams";
import { PARTNER_REDIRECT_FLOW_ID } from "@domain/flows/constants";
import { onLanding } from "@domain/leads/machines/registration";
import { asLeadPageId } from "@domain/lead-pages/utils";
import { getBrandingFromPageCtx } from "@domain/branding/ssr";
import { fetchProductTypeFromPageCtx } from "@domain/products/ssr/fetchProductTypeFromPageCtx";
import { useRouteLoading } from "@providers/RouteLoadingContextProvider/useRouteLoading";
import { EmailRegistrationComponent } from "@components/pages/EmailRegistration/EmailRegistration.component";
import { setCookieNext } from "@integration/cookies";
import { COOKIE_NAME_REDIRECT_AFTER_REG } from "@integration/cookies/cookie-names";
import { type Platform } from "@domain/leads/csr/types";
import { validPlatform } from "@components/pages/EmailRegistration/components/validPlatform";
import { ccOfferRedirectUrl } from "@domain/credit-cards/common";
import { type UrlParams } from "@integration/query-params/common";
import { decodeLoanApplicationInput } from "@utils/loanApplication/decodeLoanApplicationInput";
import { type ExperimentProps } from "@integration/experiments/types";
import { type ProductConfig } from "@domain/product-config/types";
import { fetchEnabledExperiments } from "@integration/experiments/ssr/fetchEnabledExperiments";
import { fetchProductConfig } from "@domain/product-config/ssr";
import { getPageAdsenseConfig } from "@domain/adsense/pages/getPageAdsenseConfig";
import type { BrandId } from "@domain/branding/common/types";
import type { LeadPageId } from "@domain/lead-pages/types";
import type { GetServerSideProps } from "next/types";

const EmailRegistration = dynamic(
  () =>
    import("@components/pages/EmailRegistration/EmailRegistration").then(
      (mod) => mod.EmailRegistration,
    ),
  {
    loading: () => (
      <EmailRegistrationComponent
        onSubmit={() => {}}
        shouldDisableFormSubmit={true}
        shouldDisableFields={true}
      />
    ),
  },
);

type Props = {
  leadPageSSR: LeadPageId | null;
  brandId: BrandId;
  productType: ProductType;
  platform: Platform;
  params: UrlParams;
  loanApplicationInput: LoanApplicationInput | null;
  experiments: ExperimentProps;
  productConfig: ProductConfig;
};

const Page = ({
  leadPageSSR,
  brandId,
  productType,
  platform,
  params,
  loanApplicationInput,
  experiments,
  productConfig,
}: Props) => {
  const { isPageTransitionLoading } = useRouteLoading({
    useDefaultLoader: false,
  });
  const { query } = useRouter();
  const leadPageCSR = filterQueryParams(query)[PARAM_NAME_PARTNER_URL_KEY];
  const leadPageId = leadPageSSR || asLeadPageId({ input: leadPageCSR });

  const pageAdsenseConfig = getPageAdsenseConfig({
    country: productConfig.countryCode,
    pathname: "/registration",
    query,
  });

  return (
    <EmailRegistration
      leadPageId={leadPageId}
      brandId={brandId}
      productType={productType}
      isPageTransitionLoading={isPageTransitionLoading}
      EmailRegComponent={EmailRegistrationComponent}
      platform={platform}
      params={params}
      loanApplicationInput={loanApplicationInput || undefined}
      experiments={experiments}
      productConfig={productConfig}
      adsenseProps={pageAdsenseConfig}
    />
  );
};

export const getServerSideProps: GetServerSideProps<Props> = wrap<Props>(
  async (ctx) => {
    const leadId = fetchLeadId(ctx) || undefined;
    const params = filterQueryParams(ctx?.query);
    const partnerUrlKey = params[PARAM_NAME_PARTNER_URL_KEY];
    const redirectAfterReg = params[PARAM_NAME_REDIRECT_AFTER_REG];
    const platform = validPlatform(params[PARAM_NAME_PLATFORM]) || "web";
    const loanApplicationInputBase64 =
      params[PARAM_NAME_LOAN_APPLICATION_INPUT];

    if (redirectAfterReg) {
      setCookieNext(COOKIE_NAME_REDIRECT_AFTER_REG, redirectAfterReg, {
        ...ctx,
        maxAge: 10 * 60,
      });
    }

    const productType =
      fetchProductTypeFromPageCtx(ctx) || ProductType.SubPrime;

    const { brandId } = getBrandingFromPageCtx({ req: ctx.req });

    const loanApplicationInput =
      decodeLoanApplicationInput(loanApplicationInputBase64) || null;

    const { matches } = onLanding({
      urlParams: params,
      existingLead: !!leadId,
      brandId,
    });

    if (matches("Try Redirect Directly to Partner")) {
      const destination = `${INITIATE_FLOW_PAGE_URI}?${PARAM_NAME_FLOW_ID}=${PARTNER_REDIRECT_FLOW_ID}&${PARAM_NAME_PARTNER_URL_KEY}=${partnerUrlKey}`;
      return {
        redirect: {
          permanent: false,
          destination,
        },
      };
    }

    if (matches("Redirect to Credit Card offer")) {
      return {
        redirect: {
          permanent: false,
          destination: ccOfferRedirectUrl({ params }),
        },
      };
    }

    if (matches("Insurance auto redirect page")) {
      return {
        redirect: {
          destination: `${PAGE_URI_BUY_INSURANCE_AUTO_REDIRECT}?${new URLSearchParams(
            params,
          )}`,
          permanent: false,
        },
      };
    }

    // redirect to buy motorbike insurance page
    if (matches("Motorbike Insurance Form")) {
      const destination = `${PAGE_URI_BUY_INSURANCE_MOTORBIKE}?${new URLSearchParams(
        {
          ...params,
        },
      )}`;
      return {
        redirect: {
          permanent: false,
          destination,
        },
      };
    }

    if (matches("Lead Page Landing Page")) {
      const destination = `${LEAD_PAGE_LANDING_URI}?${new URLSearchParams({
        ...params,
      })}`;

      return {
        redirect: {
          permanent: false,
          destination,
        },
      };
    }

    if (matches("Sub-Prime Offers")) {
      return {
        redirect: {
          permanent: false,
          destination: OFFERS_PAGE_URI,
        },
      };
    }

    const experiments = await fetchEnabledExperiments({
      ctx,
    });
    const productConfig = fetchProductConfig(ctx);

    return {
      props: {
        leadPageSSR: asLeadPageId({ input: partnerUrlKey }) || null,
        brandId,
        productType,
        platform,
        params,
        loanApplicationInput,
        experiments,
        productConfig,
      },
    };
  },
);

export default Page;

import { Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Text } from "@components/atoms/Text";
import type { NavLinkProps } from "./NavLink.types";

export const NavLink = ({
  arrow = "none",
  text,
  onClick,
  variant = "contentSM",
  disabled,
  dataTestId,
  textDecoration,
  sx = [],
}: NavLinkProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      data-testid={dataTestId}
      spacing={0.3}
      sx={[
        (theme) => ({ color: theme.palette.primary[900] }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={onClick}
    >
      {arrow === "back" && <ArrowBackIosIcon fontSize="medium" />}

      <Text
        disabled={disabled}
        variant={variant}
        sx={{
          textDecoration: textDecoration || "underline",
          color: (theme) => theme.palette.primary[900],
          cursor: "pointer",
        }}
      >
        {text}
      </Text>

      {arrow === "forward" && <ArrowForwardIosIcon fontSize="medium" />}
    </Stack>
  );
};

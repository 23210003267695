import { Stack } from "@mui/material";
import { Text } from "@components/atoms/Text";
import { useLocalisationContext } from "@providers/LocalisationProvider/hooks/useLocalisationContext";
import type { PhoneUnsubFooterProps } from "./PhoneUnsubFooter.types";

export const PhoneUnsubFooterComponent = ({
  sx = [],
  isUnsubscribed,
  onUnsubClick,
  onResubClick,
}: PhoneUnsubFooterProps) => {
  const { t } = useLocalisationContext();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={[
        {
          backgroundColor: "inherit",
          paddingBottom: "10px",
          width: "100%",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {isUnsubscribed ? (
        <Text
          variant="contentDefault"
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={onResubClick}
        >
          {t("footer.resubscribe_to_sms")}
        </Text>
      ) : (
        <Text
          variant="contentDefault"
          sx={{
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={onUnsubClick}
        >
          {t("footer.unsubscribe_from_sms")}
        </Text>
      )}
    </Stack>
  );
};

import axios from "axios";
import { SMS_UNSUBSCRIBE_ENDPOINT } from "../constants";
import type { AxiosRequestConfig } from "axios";

export const unsubscribePhone = async (
  leadId: string,
  phoneUnsubToken: string,
) => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: SMS_UNSUBSCRIBE_ENDPOINT,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      leadId: leadId,
      token: phoneUnsubToken,
    },
  };

  const result = await axios(config);
  return result;
};

import { type ParsedUrlQuery } from "querystring";
import { type Country } from "@jeff/common-resources/src/countries";
import { KIMBI_MX_BRAND_ID } from "@domain/branding/constants";
import { type UrlParamNames } from "@integration/query-params/common";

export type PageAdsenseConfig = Record<
  Country,
  {
    [route: string]: {
      slotId?: string;
      urlParamSpecific?: Partial<{
        [paramKey in UrlParamNames]: {
          [paramValue: string]: { slotId: string };
        };
      }> | null;
    } | null;
  } | null
>;

export const pagesAdsenseConfig: PageAdsenseConfig = {
  VN: {
    "/profile/my-activity": {
      slotId: "6109174084",
    },
    "/profile/dashboard": {
      slotId: "8352194045",
    },
    "/confirm-email": {
      slotId: "2814566958",
    },
  },
  PH: {
    "/profile/my-activity": {
      slotId: "7230684068",
    },
    "/profile/dashboard": {
      slotId: "2249820127",
    },
    "/confirm-email": {
      slotId: "6262857379",
    },
  },
  MX: {
    "/registration": {
      urlParamSpecific: {
        partnerUrlKey: {
          [KIMBI_MX_BRAND_ID]: { slotId: "7686785537" },
        },
      },
    },
    "/profile/my-activity": {
      slotId: "4604520720",
    },
    "/profile/dashboard": {
      slotId: "3538851061",
    },
    "/confirm-email": {
      slotId: "1133013728",
    },
  },
  US: null,
  LK: null,
  IN: null,
  ID: null,
};

interface Args {
  country: Country;
  pathname: string;
  query?: ParsedUrlQuery;
}

export const getPageAdsenseConfig = ({
  country,
  pathname,
  query,
}: Args): { slotId: string } | null => {
  const countryConfig = pagesAdsenseConfig[country];
  if (!countryConfig) return null;

  const pageConfig = countryConfig[pathname];
  if (!pageConfig) return null;

  if ("slotId" in pageConfig && pageConfig.slotId) {
    return { slotId: pageConfig.slotId };
  }

  if (pageConfig.urlParamSpecific) {
    for (const [paramKey, paramConfig] of Object.entries(
      pageConfig.urlParamSpecific,
    )) {
      const paramValue = query?.[paramKey];

      if (typeof paramValue === "string" && paramConfig[paramValue]) {
        return paramConfig[paramValue];
      }
    }
  }

  return null;
};

import { Box, Stack } from "@mui/material";
import { PhoneUnsubFooter } from "@components/organisms/PhoneUnsubFooter/PhoneUnsubFooter";
import { ResponsiveSlot } from "./components";
import type { BaseLayoutComponentProps } from "./BaseLayout.types";

export const BaseLayoutComponent = ({
  content,
  filterBar,
  footer,
  navBar,
  contentSx,
  filterBarSx = {
    backgroundColor: (theme) => theme.palette.primary[200],
  },
  footerSx,
  navBarSx = {
    backgroundColor: (theme) => theme.styles.colors.navBarBackground,
  },
  handleUnsubClick,
  handleResubClick,
  isUnsubFooterEnabled,
  isUnsubscribed,
}: BaseLayoutComponentProps) => {
  return (
    <Stack sx={{ minHeight: "100vh" }}>
      <ResponsiveSlot sx={navBarSx}>{navBar}</ResponsiveSlot>

      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10000,
          backgroundColor: "white",
        }}
      >
        <ResponsiveSlot sx={filterBarSx}>{filterBar}</ResponsiveSlot>
      </Box>

      <Stack sx={{ flex: 1 }} alignItems="center">
        <ResponsiveSlot
          sx={[
            {
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "stretch",
              minWidth: "0px",
            },
            ...(Array.isArray(contentSx) ? contentSx : [contentSx]),
          ]}
        >
          {content}
        </ResponsiveSlot>

        <ResponsiveSlot sx={footerSx}>{footer}</ResponsiveSlot>
        {isUnsubFooterEnabled && (
          <ResponsiveSlot
            sx={{ backgroundColor: (theme) => theme?.palette?.primary[900] }}
          >
            <PhoneUnsubFooter
              onUnsubClick={handleUnsubClick}
              onResubClick={handleResubClick}
              isUnsubscribed={isUnsubscribed}
            />
          </ResponsiveSlot>
        )}
      </Stack>
    </Stack>
  );
};

import CircularProgress from "@mui/material/CircularProgress";
import { Text } from "@components/atoms/Text";
import { ButtonStyled } from "./Button.styled";
import type { ButtonProps } from "./Button.types";

export const Button = ({
  color,
  buttonLabel,
  loading = false,
  fullWidth = false,
  disabled,
  sx = [],
  textSx,
  ...props
}: ButtonProps) => {
  return (
    <ButtonStyled
      color={color}
      disabled={disabled}
      {...props}
      sx={[
        { width: fullWidth ? "100%" : "fit-content" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {loading && (
        <CircularProgress
          sx={(theme) => ({
            color: disabled
              ? theme.palette.neutral[600]
              : color === "primary"
                ? theme.palette.common.white
                : theme.palette.primary[900],
          })}
          size={24}
        />
      )}
      {!loading && (
        <Text variant="contentBold" sx={textSx}>
          {buttonLabel}
        </Text>
      )}
    </ButtonStyled>
  );
};

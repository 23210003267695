import { asReportable, onError } from "@integration/bugsnag/csr";
import { safeLocalStorage } from "@utils/safeLocalStorage";
import { COOKIE_NAME_ENABLED_FEATURES } from "@integration/cookies/cookie-names";
import { type ExperimentId } from "@integration/experiments/constants";
import { type ExperimentProps } from "@integration/experiments/types";
import { isFeatureEnabled as isFeatureEnabledCommon } from "../common/isFeatureEnabled";
import { FeatureStateSchema, type Feature, type FeatureState } from "../types";

export const isFeatureEnabled = ({
  feature,
  experiments,
}: {
  feature: Feature | ExperimentId;
  experiments?: ExperimentProps | null;
}): boolean => {
  const experimentEnabled = !!experiments?.enabled[feature as ExperimentId];
  if (experimentEnabled) {
    return true;
  }
  const result = isFeatureEnabledCommon({ name: feature, onError });
  if (result) {
    return result;
  }
  try {
    const cookieName = COOKIE_NAME_ENABLED_FEATURES + feature;
    return safeLocalStorage.getItem(cookieName) === "true";
  } catch (err) {
    const e = asReportable(err);
    onError(e);
  }
  return false;
};

export const getFeatureState = ({
  feature,
  experiments,
}: {
  feature: Feature | ExperimentId;
  experiments: ExperimentProps | null;
}): FeatureState => {
  const enabledByCookie = isFeatureEnabledCommon({ name: feature, onError });
  if (enabledByCookie) {
    return "test-group-a";
  }
  const experimentEnabled = !!experiments?.enabled[feature as ExperimentId];
  if (!experimentEnabled) {
    return "control-group";
  }
  const maybeVariant = FeatureStateSchema.safeParse(
    experiments?.variants[feature as ExperimentId],
  );
  if (maybeVariant.success) {
    return maybeVariant.data;
  }
  return "test-group-a";
};

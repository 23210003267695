import { Typography, styled } from "@mui/material";
import type { BaseTypographyVariants } from "@theme/base/theme.types";
import type { TextProps } from "./Text.types";

const variantMapping: Record<
  BaseTypographyVariants,
  keyof JSX.IntrinsicElements
> = {
  h1: "h1",
  h1mobile: "h1",
  h2: "h2",
  h2mobile: "h2",
  h3: "h3",
  h3mobile: "h3",
  h4: "h4",
  h4mobile: "h4",
  h5: "h5",
  contentDefault: "div",
  contentM: "div",
  contentBold: "div",
  contentSM: "div",
  contentSMBold: "div",
  contentXS: "div",
  calculatorAmount: "div",
  calculatorUnit: "div",
};

export const Text = styled(
  ({ variant = "contentDefault", ...props }: TextProps) => (
    <Typography component={variantMapping[variant]} {...props} />
  ),
)(({ theme, variant, disabled }) => ({
  ...theme.typography[variant || "contentDefault"],
  opacity: disabled ? 0.5 : 1,
}));

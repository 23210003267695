import axios from "axios";
import { SMS_RESUBSCRIBE_ENDPOINT } from "../constants";
import type { AxiosRequestConfig } from "axios";

export const resubscribePhone = async (leadId: string) => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: SMS_RESUBSCRIBE_ENDPOINT,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      leadId: leadId,
    },
  };

  const result = await axios(config);
  return result;
};

import { z } from "zod";
import type { featureList } from "./features";

export type Feature = (typeof featureList)[number];

export const FeatureStateSchema = z.enum([
  "control-group",
  "test-group-a",
  "test-group-b",
]);

export type FeatureState = z.infer<typeof FeatureStateSchema>;
